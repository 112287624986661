@fontface {
  font-family: 'Bungee Inline', cursive;
  src: url('https://fonts.googleapis.com/css?family=Poppins:400,500,600')
}

body {
  font-family: "Poppins", sans-serif;
}

.btn-disabled {
  opacity: .5!important;
  cursor: not-allowed!important;
}

.list-group-flush .list-group-item a.active{
  border-left: 3px solid #3c8dbc;
}

/* File Sorter */
.photos-files_left-pane_wrapper,
.photos-files_right-pane_wrapper {
  /* margin: 15px; */
  box-shadow: 0px 0px 30px #eee;
  border-radius: 5px;
}
.photos-files_heading-title {
  margin-top: 0px;
  margin-bottom: 15px!important;
  display: inline-block;
}
.photos-files_box {
  margin-top: 15px;
  padding: 10px 5px;
  background-color: #f5f5f5;
}
.photos-files_box_selection {
  margin-right: 5px;
}
.photos-files_box_selection:hover {
  color: #fff;
}
.file-sorter_box_thumb {
  cursor: pointer;
}
.file-sorter_box_thumb.d-inline-block {
  display: inline-block;
}
.file-sorter_box_thumb.d-inline-grid {
  display: inline-grid;
}
.file-sorter_box_thumb img {
  /* margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-left: 5px; */
  width: 126px;
  height: 116px;
}
.file-sorter_box_thumb.selected .photos-files_img-cover-wrapper,
.file-sorter_box_thumb.selected>img {
  background-color: #3c8dbccc;
  border-color: #3c8dbc;
  box-shadow: 0px 0px 8px #3c8dbc;
  color: #fff;
}
.photos-files_img-cover-wrapper {
  width: 126px;
  /* height: 86px; */
  display: block;
  position: static;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  float: left;
  padding: 4px;
   margin: 5px 5px 0px 5px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  color: #1a1a1a;
}
.photos-files_img-cover-inner {
  width: 116px;
  height: 86px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
.photos-files_box>.tab-content>div {
  max-height: 480px;
  overflow-y: auto;
}
.photos-files_box>.tab-content a>img {
  overflow: hidden;
}
/* Left Pane */
.event_file-sorter_teams-list {
  max-height: 480px;
  overflow-y: auto;
}
/* Right Pane */
.photos-files_right-pane_wrapper .dropdown-menu,
.photos-files_filter-box .dropdown-menu,
.file-sorter_paginator .dropdown-menu {
  padding: 5px 0;
  min-width: 160px
}
.photos-files_filter-box .dropdown-menu,
.file-sorter_paginator .dropdown-menu {
  min-width: 70px;
}
.filterShowOptions {
  margin-left: 5px;
}
.photos-files_img-cover_controls {
  float: right;
  margin-top: -1px;
  opacity: 0.5;
}
.photos-files_img-cover_controls:hover {
  opacity: 1;
}
.orderBy-filter {
  margin-right: 5px;
}
.orderBy_filter_label {
  margin-right: 10px;
}

/* Paginator */
.photos-files_filter-box .dropdown-menu,
.file-sorter_paginator .dropdown-menu {
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
}

/* Previewer */
.photo-previewer .modal-body {
  padding: 0px;
}
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
  flex-wrap: wrap;
}
.row-eq-height > [class*='col-'] {
  display: flex;
  flex-direction: column;
}
.photo-thumb_preview_left-pane
.left-pane__inner {
  max-height: calc(100vh - 71px);
  height: 100%;
  overflow-y: auto;
  display: flex;
  background-color: #222d32;
}
.right-pane_heading {
  border-bottom: 1px solid #eee;
  padding-bottom: 8px;
}
.photo-thumb_preview_right-pane .table td {
  max-width: 200px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.photo-thumb_preview_right-pane {
  background-color: #fafafa;
  border-left: 1px solid #e5e5e5;
}
.photo-thumb_preview_right-pane {
  padding: 15px;
}
ul#event-photo-tab {
  max-height: 500px;
  overflow-y: auto;
}
.btn-group-xs>.btn, .btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
#topnav{
  z-index: 1;
}
.custom-header{
  top: 0;
  position: absolute;
  right: 0;
  padding-top: 10px;
  padding-right: 10px;
  z-index: 9999;
}
.btn-icon{
  padding: 10px;
  border-left: 0!important;
  cursor: pointer;
}
.btn-icon i{
  font-size: 20px;
  color: rgba(255, 255, 255, 0.9);
}